import React, { useRef, useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { AuthContext } from "../../contexts/AuthProvider";

export function CheckExpirationDateOfToken() {
  const [isValid, setIsValid] = useState(false);
  const { handleLogout } = useContext(AuthContext);
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    let interval;

    const checkValidity = () => {
      const expirationDate = Cookies.get("userTokenAcces");

      if (!expirationDate) {
        console.log("CheckExpirationDateOfToken: Expiration date not found in cookies");
      } else {
        console.log("CheckExpirationDateOfToken: Expiration found in cookies", expirationDate);
      }

      // Si le token n'est pas valide, déconnectez l'utilisateur et arrêtez de vérifier
      if (!expirationDate || new Date() > new Date(expirationDate)) {
        setIsValid(false);
        if (isMountedRef.current) {
          alert("Session écoulée! Reconnectez-vous!");
          handleLogout();
        }
        clearInterval(interval);
      } else {
        setIsValid(true);
      }
    };

    checkValidity();
    interval = setInterval(checkValidity, 60 * 1000);

    return () => {
      isMountedRef.current = false;
      clearInterval(interval);
    };
  }, [handleLogout]);

  return (
    <div
      style={{
        width: "12px",
        height: "12px",
        marginRight: "8px",
        borderRadius: "50%",
        backgroundColor: isValid ? "green" : "red",
      }}
      title={isValid ? "Session active" : "Il faut se reconnecter"}
    />
  );
}