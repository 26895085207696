import React, { createContext, useState } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [triggerAddAction, setTriggerAddAction] = useState(false); // à true si on veut ajouter une nouvelle action
  const [triggerAddCorpsDeMetiers, setTriggerAddCorpsDeMetiers] =
    useState(false); // à true si on veut ajouter un nouveau CorpsDeMetiers
  const [triggerAddModele, setTriggerAddModele] = useState(false);
  const [triggerAddPrevisionnel, setTriggerAddPrevisionnel] = useState(false);
  const [triggerAddEcheance, setTriggerAddEcheance] = useState(false);

  // Gestion des rôles
  const [isLogged, setIsLogged] = useState(false); // Dans App.js lance le login is isLogged is false
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true

  const [isAgence, setIsAgence] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true
  const [isPartenaire, setIsPartenaire] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true
  const [isClient, setIsClient] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true
  const [isPrestataire, setIsPrestataire] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true

  const [isHttps, setIsHttps] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true

  const [userId, setUserId] = useState(""); // Utilisateur Connecté - User log in
  const [userTiersId, setUserTiersId] = useState(null); // TiersId auquel est rattaché le userId
  const [userRole, setUserRole] = useState(""); // Appelé depuis le composant Login (Admin, Manager, Utilisateur)


  const [selectedMenu, setSelectedMenu] = useState("");
  const [zone2Content, setZone2Content] = useState("");
  const [projetId, setProjetId] = useState(""); // Pointe sur le projetId en cours
  const [projetCible, setprojetCible] = useState(""); // On récupère la cible du projet issue du modèle

  const [modeleId, setModeleId] = useState(""); // Pointe sur le modeleId en cours
  const [utilisateurId, setUtilisateurId] = useState(""); // Pointe sur l'utilisateurId en cours
  const [tiersId, setTiersId] = useState(null); // Pointe sur le tiersId en cours
  const [agenceId, setAgenceId] = useState(""); // Pointe sur les Paramètres communs
  const [etapeId, setEtapeId] = useState(null);

  const [modeleOrder, setModeleOrder] = useState([]); // Nouvel état pour l'ordre des modèles

  const [debugAffichage, setDebugAffichage] = useState(false);
  const [modificationDestinataires, setModificationDestinataires] =
    useState(false);

  // Pour la gestion des compteurs de projets enCours et enArchive



  // Pour déployer les Destinataires
  const [isDestinatairesOpen, setToggleDestinataire] = useState(false);
 
  // Dans votre fournisseur de contexte
  const [selectedArchive, setSelectedArchive] = useState(null);
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  
  const [addNewUser, setNewUser] = useState(false);
  const [newPrevisionnel, setNewPrevisionnel] = useState(false);
  const [newEcheancier, setNewEcheancier] = useState(false);

  const [newCorpsDeMetier, setNewCorpsDeMetier] = useState(false);
  const [newAction, setNewAction] = useState(false);
  

  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const toggleSearch = () => setShowSearch(!showSearch);

  const handleShowSearch= (flag) => {
    console.log("AuthProvider setShowSearch", flag);
    setShowSearch(flag);
  };
  const handleSearchTerm = (searchTerm) => {
    console.log("AuthProvider setSearchTerm", searchTerm);
    setSearchTerm(searchTerm);
  };

  const handleAgenceId = (paramId) => {
    console.log("AuthProvider handleAgenceId", paramId);
    setAgenceId(paramId);
  };

  const handleAddNewAction = (action) => {
    console.log("AuthProvider handleAddNewAction", action);
    setNewAction(action);
  };

  const handleAddNewCorpsDeMetier = (corpsDeMetier) => {
    console.log("AuthProvider handleAddNewCorpsDeMetier", corpsDeMetier);
    setNewCorpsDeMetier(corpsDeMetier);
  };

  // Fonctions pour gérer l'ajout de nouveaux éléments
  const handleAddNewUser = (user) => {
    console.log("AuthProvider handleAddNewUser", user);
    setNewUser(user);
  };



  const handleIsHttps = (status) => {
    console.log("AuthProvider handleIsHttps", status);
    setIsHttps(status);
  };
  const handleIsMenuToggled = (toggled) => {
    setIsMenuToggled(toggled);
  };

  const handleAddNewPrevisionnel = (previsionnel) => {
    // Logique pour ajouter un nouvel élément prévisionnel
    setNewPrevisionnel(previsionnel);
  };

  const handleAddNewEcheancier = (echeancier) => {
    // Logique pour ajouter un nouvel élément échéancier
    setNewEcheancier(echeancier);
  };



 

  // // Méthode pour changer la sélection
  // const handleSelectedArchive = (type) => {
  //   console.log("AuthProvider handleSelectedArchive", type);
  //   setSelectedArchive( type );
  // };

  const handleprojetCible = (cible) => {
    console.log("AuthProvider changeprojetCible", cible);
    setprojetCible( cible );
  };

  const handleToggleDestinataires = (flag) => {
    console.log("AuthProvider handleToggleDestinataires", flag);
    setToggleDestinataire(flag);
  };

  const handleModelOrder = (items) => {
    console.log("AuthProvider handleModelOrder", items);
    setModeleOrder(items);
  };

  

  const handleModificationDestinataires = (action) => {
    console.log("AuthProvider handleModificationDestinataires", action);
    setModificationDestinataires(action);
  };

  const handleEtapeId = (etape) => {
    console.log("AuthProvider handleEtapeId", etape);
    setEtapeId(etape);
  };

  const handleProjetId = (projetId) => {
    console.log("AuthProvider handleProjetId", projetId);
    setProjetId(projetId);
  };

  const handleModeleId = (modeleId) => {
    console.log("AuthProvider handleModeleId", modeleId);
    setModeleId(modeleId);
  };

  const handleSelectedMenu = (selectedMenu) => {
    console.log("AuthProvider handleSelectedMenu", selectedMenu);
    setSelectedMenu(selectedMenu);
  };

  // On stocke l'Utilisateur de travail
  const handleUtilisateurId = (utilisateurId) => {
    console.log("AuthProvider handleUtilisateurId", utilisateurId);
    setUtilisateurId(utilisateurId);
  };

  // On stocke le tiers du serId
  const handleUserTiersId = (tiersId) => {
    console.log("AuthProvider handleUserTiersId", tiersId);
    setUserTiersId(tiersId);
  };

  const handleTriggerAddAction = (flag) => {
    console.log("AuthProvider handleTriggerAddAction", flag);
    setTriggerAddAction(flag);
  };

  const handleTriggerAddModele = (flag) => {
    console.log("AuthProvider handleTriggerAddModele", flag);
    setTriggerAddModele(flag);
  };

  const handleTriggerAddCorpsDeMetiers = (flag) => {
    console.log("AuthProvider handleTriggerAddCorpsDeMetiers", flag);
    setTriggerAddCorpsDeMetiers(flag);
  };

  const handleTriggerAddPrevisionnel = (flag) => {
    console.log("AuthProvider handleTriggerAddPrevisionnel", flag);
    setTriggerAddPrevisionnel(flag);
  };

  const handleTriggerAddEcheance = (flag) => {
    console.log("AuthProvider handleTriggerAddEcheance", flag);
    setTriggerAddEcheance(flag);
  };


  const handleDebugAffichage = (flag) => {
    console.log("AuthProvider handleDebugAffichage", flag);
    setDebugAffichage(flag);
  };

  // On stocke le tiers de travail
  const handleTiersId = (tiersId) => {
    console.log("AuthProvider handleTiersId", tiersId);
    setTiersId(tiersId);
  };

  const handleLogin = async (userId, userRole) => {
    console.log("AuthProvider handleLogin", userId, userRole);
    setIsLogged(true);
    // setTiersId(tiersId);
    setUserId(userId);
    setZone2Content("");
    setProjetId("");
    setModeleId("");
    setUserRole(userRole);

  };

  const handleLogout = () => {
    console.log("AuthProvider handleLogout", tiersId);
    setIsLogged(false);
    setUserId("");
    setSelectedMenu("");
    setZone2Content("");
    setProjetId("");
    setModeleId("");
    setAgenceId("");
    Cookies.remove("userTokenAcces");
    Cookies.remove("expirationDate");
    Cookies.remove("userNomConnecté");
    Cookies.remove("userPrénomConnecté");
  };



  return (
    <AuthContext.Provider
      value={{
        isLogged,
        isManager,
        setIsManager,
        isAdmin,
        setIsAdmin,
        isClient,
        setIsClient,
        isPartenaire,
        setIsPartenaire,
        userId,
        agenceId,
        handleAgenceId,
        utilisateurId,
        isPrestataire,
        setIsPrestataire,

        isHttps,
        handleIsHttps,
        isAgence,
        setIsAgence,
        projetCible,
        handleprojetCible,

        isMenuToggled,
        handleIsMenuToggled,

        toggleSearch,
        showSearch,
        handleShowSearch,
        handleSearchTerm, 
        searchTerm,

        userTiersId,
        tiersId,
        userRole,
        selectedMenu,
        zone2Content,
        projetId,
        modeleId,
        etapeId,

        addNewUser,
        handleAddNewUser,
        newPrevisionnel,
        handleAddNewPrevisionnel,
        newEcheancier,
        handleAddNewEcheancier,
        newCorpsDeMetier,
        handleAddNewCorpsDeMetier,
        newAction,
        handleAddNewAction,

        modeleOrder,
        handleModelOrder,

        modificationDestinataires,
        handleModificationDestinataires,

        handleProjetId,
        handleModeleId,
        handleEtapeId,
        handleSelectedMenu,
        handleUtilisateurId,
        handleTiersId,
        handleUserTiersId,
        handleLogin,
        handleLogout,
        triggerAddAction,
        handleTriggerAddAction,
        triggerAddCorpsDeMetiers,
        handleTriggerAddCorpsDeMetiers,
        triggerAddModele,
        handleTriggerAddModele,
        triggerAddPrevisionnel,
        handleTriggerAddPrevisionnel,

        triggerAddEcheance,
        handleTriggerAddEcheance,

        debugAffichage,
        handleDebugAffichage,

        isDestinatairesOpen,
        handleToggleDestinataires,

        selectedArchive,
        // handleSelectedArchive,

      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
