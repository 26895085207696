import React, { useState, useContext, useEffect } from "react";
import {
  Navbar,
  Form,
  FormControl,
  Button,
  Nav,
  Container,
  Card,
} from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { FooterAccueil } from "../Footer/FooterAccueil";
import RequestForm from "../RequestForm/RequestForm";

import "./css/login.css";

import { BsPerson } from "react-icons/bs";


export function Login() {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showAvis, setShowAvis] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const { urlServeurNode } = useContext(ServerContext);
  const {
    handleLogin,
    setIsManager,
    setIsAdmin,
    setIsPartenaire,
    setIsAgence,
    setIsClient,
    setIsPrestataire,
    handleAgenceId,
    handleSelectedMenu,
    handleTiersId,
    handleUserTiersId,
    isHttps,
    handleIsHttps,
  } = useContext(AuthContext);

  const toggleLoginForm = () => setShowLoginForm((prev) => !prev);
  const toggleAvis = () => setShowAvis((prev) => !prev);
  const toggleDetails = () => setShowDetails((prev) => !prev);
  const toggleNavbar = () => setIsOpen((prev) => !prev);
  const handleRequestClick = () => setShowRequestForm((prev) => !prev);

  useEffect(() => {
    const statutHttps = process.env.HTTPS === "true";
    handleIsHttps(statutHttps);
    console.log("HTTPS Status: ", statutHttps);
  }, [handleIsHttps]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${urlServeurNode}/api/login`,
        credentials,
        {
          headers: { "Content-Type": "application/json; charset=utf-8" },
        }
      );
      processLoginResponse(response);
    } catch (err) {
      alert("Erreur de connexion : login ou mot de passe incorrect.");
      setIsLoading(false);
    }
  };

  const processLoginResponse = async (response) => {
    const { userTokenAcces, expirationDate, userId } = response.data;

    Cookies.set("userTokenAcces", userTokenAcces, {
      expires: new Date(expirationDate),
      secure: isHttps,
      sameSite: "Strict",
    });

    customAxios.defaults.headers.Authorization = `Bearer ${userTokenAcces}`;

    Cookies.set("userNomConnecté", response.data.nom, {
      secure: isHttps,
      sameSite: "strict",
    });

    Cookies.set("userPrénomConnecté", response.data.prenom, {
      secure: isHttps,
      sameSite: "strict",
    });

    await handleEntiteTiers(userId);
    const utilisateursResponse = await customAxios.get(
      `${urlServeurNode}/api/utilisateurs/${userId}?actif=true`
    );
    handleRolesAndPermissions(utilisateursResponse.data);
    handleLogin(userId, utilisateursResponse.data.role);
    setIsLoading(false);
  };

  const handleEntiteTiers = async (userId) => {
    const entitéTiers = await customAxios.get(
      `${urlServeurNode}/api/login/agence/${userId}`
    );
    handleAgenceId(entitéTiers.data.tiersId);
    handleTiersId(entitéTiers.data.tiersId);
    handleUserTiersId(entitéTiers.data.tiersId);

    const typeTiers = entitéTiers.data.typeTiers;
    Cookies.set("userTypeTiers", typeTiers, {
      secure: isHttps,
      sameSite: "strict",
    });

    setIsPartenaire(typeTiers === "PARTENAIRE");
    setIsAgence(typeTiers === "AGENCE");
    setIsClient(typeTiers === "CLIENT");
    setIsPrestataire(typeTiers === "PRESTATAIRE");

    definirMenuParDefautALaConnexion(typeTiers);
  };

  const handleRolesAndPermissions = (data) => {
    setIsManager(data.role === "Manager");
    setIsAdmin(data.role === "Admin");
  };

  const definirMenuParDefautALaConnexion = (typeTiers) => {
    const menuMapping = {
      PARTENAIRE: "ATTENTES",
      PRESTATAIRE: "REQUÊTES",
      AGENCE: "REQUÊTES",
      ADMINISTRATEUR: "ADMIN AGENCES",
    };
    handleSelectedMenu(menuMapping[typeTiers] || "DEFAULT");
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${urlServeurNode}/api/password-reset`,
        {
          email: resetEmail,
        }
      );
      alert(
        "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail."
      );
    } catch (error) {
      console.error("Erreur lors de la demande de réinitialisation", error);
      alert(
        "Erreur lors de la demande de réinitialisation. Veuillez réessayer."
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        color: "var(--texte-fond)",
      }}
    >




      {showLoginForm && (
        <div className="form-inline mt-5">
          <Form onSubmit={handleSubmit} className="d-flex align-items-center">
            <FormControl
              type="text"
              placeholder="Login"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              autoComplete="username"
              aria-label="Login"
              className="mr-3"  
            />
            <FormControl
              type="password"
              placeholder="Mot de passe"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              autoComplete="current-password"
              aria-label="Mot de passe"
              className="mr-3" 
            />
            <Button variant="outline-success" type="submit" disabled={isLoading} className="w-100">
              {isLoading ? "Loading..." : "Se connecter"}
            </Button>
          </Form>
          
        </div>
      )}  

      {showLoginForm && (
        <div className="forgot-password">
           <a href="/forgot-password" >
             Mot de passe oublié ?
           </a>
         </div>
        )}

      {showLoginForm && (
        <div className="inscription">
           <a href="/inscription" >
             Inscription
           </a>
         </div>
        )}

      {showLoginForm && (
        <div className="form-inline">
          <hr
              // className="mt-1"
              style={{
                width: "40%",
                height: "3px",
                margin: "0 auto",
                backgroundColor: "var(--texte-fond)",
                border: "none",
              }}
            />   
        </div>
      )}
        

      <h1
        className="text-center display-4 mt-5"
        style={{ fontWeight: "600", margin: "20px" }}
      >
        Bienvenue sur iAkka
      </h1>

      <Container
        className="text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <p style={{ fontSize: "20px", padding: "10px 20px" }}>
          Découvrez iAkka, la solution ultime pour simplifier et optimiser la
          gestion de vos processus métier. <br />
          Rejoignez-nous et révolutionnez la gestion de vos projets dès
          aujourd'hui !
        </p>
        <Button
          variant="primary"
          size="lg"
          style={{
            fontSize: "15px",
            padding: "10px 30px",
            margin: "5px 16px",
            borderRadius: "12px",
            backgroundColor: "var(--bg-gris-fonce)",
          }}
          onClick={toggleDetails}
        >
          iAkka en savoir plus
        </Button>

        {showDetails && (
          <Card className="card-style-acceuil">
            <Card.Body>
              <Card.Text>
                <div
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    padding: "20px",
                    borderRadius: "18px",
                    backgroundColor: "var(--bg-gris-tres-fonce)",
                  }}
                >
                  <h4>Fonctionnalités principales :</h4>
                  <p>Gestion des étapes avec description et délais.</p>
                  <p>Notifications automatiques pour les parties prenantes.</p>
                  <p>Suivi en temps réel de l’avancement des projets.</p>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        )}

        <hr
          className="my-5"
          style={{
            width: "40%",
            height: "3px",
            margin: "0 auto",
            backgroundColor: "var(--texte-fond)",
            border: "none",
          }}
        />

        <div className="d-flex flex-column flex-sm-row align-items-center">
          <Button
            variant="primary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
            onClick={toggleLoginForm}  
          >
            iAkka se connecter
          </Button>


          <Button
            variant="primary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
          >
            iAkka s'inscrire
          </Button>

          <Button
            variant="primary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
          >
            iAkka essayer
          </Button>

          <Button
            variant="secondary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
            onClick={handleRequestClick}
          >
            iAkka demander
          </Button>
        </div>

        {showRequestForm && (
          <div style={{ margin: "30px" }}>
            <RequestForm onSubmit={() => setShowRequestForm(false)} />
          </div>
        )}

        <hr
          className="my-5"
          style={{
            width: "40%",
            height: "3px",
            margin: "0 auto",
            backgroundColor: "var(--texte-fond)",
            border: "none",
          }}
        />

        <Button
          variant="primary"
          size="lg"
          style={{
            fontSize: "15px",
            padding: "10px 30px",
            margin: "5px 16px",
            borderRadius: "12px",
            backgroundColor: "var(--bg-gris-fonce)",
          }}
          onClick={toggleAvis}
        >
          iAkka lire
        </Button>

        {showAvis && (
          <div className="text-center" style={{ marginTop: "20px" }}>
            <Card
              className="card-style-acceuil"
              style={{ borderRadius: "18px" }}
            >
              <Card.Body
                style={{
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  borderRadius: "18px",
                }}
              >
                <Card.Text style={{ borderRadius: "18px", padding: "10px" }}>
                  "Maintenant, grâce à iAkka, chaque membre de l'équipe a son
                  propre assistant personnel digital, rendant tout
                  incroyablement organisé et accessible."
                </Card.Text>
                <footer className="blockquote-footer">
                  Thomas, <cite title="Source Title">partenaire étonné</cite>
                </footer>
              </Card.Body>
            </Card>

            <Card
              className="card-style-acceuil"
              style={{ marginTop: "10px", borderRadius: "18px" }}
            >
              <Card.Body
                style={{
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  borderRadius: "18px",
                }}
              >
                <Card.Text style={{ borderRadius: "18px", padding: "10px" }}>
                  "iAkka a transformé la façon dont nous gérons nos projets."
                </Card.Text>
                <footer className="blockquote-footer">
                  Marc, <cite title="Source Title">client satisfait</cite>
                </footer>
              </Card.Body>
            </Card>

            <Card
              className="card-style-acceuil"
              style={{ marginTop: "10px", borderRadius: "18px" }}
            >
              <Card.Body
                style={{
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  borderRadius: "18px",
                }}
              >
                <Card.Text style={{ borderRadius: "18px", padding: "10px" }}>
                  "L'efficacité qu'apporte iAkka à notre travail est comparable
                  à un orchestre symphonique bien accordé."
                </Card.Text>
                <footer className="blockquote-footer">
                  Anissa,{" "}
                  <cite title="Source Title">maître d'oeuvre rigoureuse</cite>
                </footer>
              </Card.Body>
            </Card>
          </div>
        )}
      </Container>

      <FooterAccueil />
    </div>
  );
}
