import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Cookies from "js-cookie";

import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Login } from "./components/Login/Login";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Zone1 } from "./components/Zone1";
import { Zone2 } from "./components/Zone2";
import { Zone3 } from "./components/Zone3";
import { Zone4 } from "./components/Zone4";
import ServerContext from "./contexts/ServeurProvider";

import { AuthContext } from "./contexts/AuthProvider";
import "./css/App.css";

const App = () => {
  const {
    isLogged,
    isPartenaire,
    isAgence,
    isClient,
    selectedMenu,
    handleTiersId,
  } = useContext(AuthContext);

  const [tiersType, setTiersType] = useState(null);

  let serverNode;
  const monUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  // console.log(`Taille de l'écran : ${breakpoint}`);
  // console.log(`Adresse du serveur React en cours : ${monUrl}`);
  // console.log(`Adresse origin du serveur React en cours : ${window.location.origin}`);

  const currentOrigin = window.location.origin;
  // console.log("App DEBUT currentOrigin isLogged", currentOrigin, isLogged);
  // Check the current origin and assign serverNode accordingly
  if (
    currentOrigin === "https://www.iakka.fr" ||
    currentOrigin === "https://iakka.fr"
  ) {
    // Si exécuté sur
    serverNode = "https://iakka.fr:4000"; // Adresse du serveur Node
    console.log(`Sur le serveur de production: ${serverNode} ${monUrl}`);
  } else if (
    currentOrigin === "https://www.iakka.io" ||
    currentOrigin === "https://iakka.io"
  ) {
    // Si exécuté depuis Safari https://iakka.io
    serverNode = "https://iakka.io"; // Adresse du serveur Node

    console.log(`Sur le serveur iakka.io: ${serverNode} ${monUrl}`);
  } else {
    // Sinon, on suppose un développement local avec Docker
    serverNode = "http://localhost:4000"; // Adresse du serveur Node avec le port 4000 externe pour Docker
    console.log(
      `App Environnement de développement avec Docker: ${serverNode} ${monUrl}`
    );
  }

  console.log(
    `App Adresse du serveur Node en cours : ${serverNode} selectedMenu ${
      selectedMenu ? selectedMenu : "Aucun menu sélectionné."
    } `
  );




  useEffect(() => {
    if (
      selectedMenu === "ALL CLIENTS" ||
      selectedMenu === "ALL PARTENAIRES" ||
      selectedMenu === "ALL PRESTATAIRES"
    ) {
      handleTiersId(null);
    }
  }, [selectedMenu]); // Dépend de selectedMenu et handleTiersId

  useEffect(() => {
    const typeTiers = getTiersTypeFromCookies();
    console.log("App useEffect getTiersTypeFromCookies", typeTiers, isAgence);
    setTiersType(typeTiers);
  }, [isClient, isPartenaire, isAgence]);

  function getTiersTypeFromCookies() {
    const typeTiers = Cookies.get("userTypeTiers");
    const isAttachedToAllAgences =
      Cookies.get("isAttachedToAllAgences") === "true"; // Assurez-vous que la comparaison est faite avec une chaîne

    return isAttachedToAllAgences ? "AGENCE" : typeTiers;
  }

  return (
    <ServerContext.Provider value={{ urlServeurNode: serverNode }}>
      <Router>
        {!isLogged ? (
          <>
            <Login />
          </>
        ) : (
          <>
            <Header />
            <AgenceContent tiersType={tiersType} />
            <Footer />
          </>
        )}
      </Router>
    </ServerContext.Provider>
  );
};

// Important de laisser AgenceContent à l'extérieur de la
const AgenceContent = ({ tiersType }) => {
  const { selectedMenu, isMenuToggled, handleIsMenuToggled } = useContext(AuthContext);

  useEffect(() => {
    if (isMenuToggled && selectedMenu) {
      handleIsMenuToggled(!isMenuToggled);
    }
  }, [selectedMenu]);

  console.log(
    "App AgenceContent selectedMenu DEBUT",    isMenuToggled,
    selectedMenu,
    tiersType,
  );

   const getZone1Class = (tiersType) => {
      let retour = "col-xl-2 d-none d-xl-block";
      console.log("App AgenceContent getZone1Class", isMenuToggled, tiersType, retour);
      return retour;
  };

  const getZone2Class = (selectedMenu, tiersType) => {
    let retour;
    console.log(
      "App AgenceContent getZone2Class",
      isMenuToggled,
      selectedMenu,
      tiersType,
    );
    switch (selectedMenu) {
      case "ALL PROJETS":
      case "ALL CLIENTS":
      case "ALL PRESTATAIRES":
      case "ALL PARTENAIRES":
      case "ALL DASHBOARD":
      case "PROJETS CLIENTS":
      case "PROJETS PARTENAIRES":
      case "SYNTHÈSES":
      case "PARTENAIRES":
      case "CLIENTS":
        // BOOTSTRAP OK
        retour = "col-xl-2 col-lg-2 col-md-2 col-sm-2";
        console.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "NEW AGENCE":
      case "UTILISATEURS":
      case "ALL AGENCES":
      case "ADMIN AGENCES":
      case "ADMIN GÉNÉRAL":
      case "ADMIN MODÈLES":
        retour = "col-xl-3 col-lg-3 col-md-3 col-sm-3";
        console.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "NEW MODELL":
      case "NEW CLIENT":
      case "NEW MODÈLE":
      case "NEW PRESTATAIRE":
      case "NEW PARTENAIRE":
        retour = "col-xl-4 col-lg-4 col-md-4 col-sm-4";
        console.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "ALL DOCUMENTS":
        retour = "col-xl-5 col-lg-5 col-md-5 col-sm-5";
        console.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "REQUÊTES":
      case "ATTENTES":
      case "RÉPONSES":
      case "DÉPÔT EXPRESS":
        switch (tiersType) {
          case "AGENCE":
          case "PRESTATAIRE":
            retour = "col-xl-8 col-lg-10 col-md-10 col-sm-10";
            console.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
            return retour;

          case "CLIENT":
          case "PARTENAIRE":
            retour = "col-xl-12 col-lg-12 col-md-12 col-sm-12";
            return retour;

          default:
            return null; // Ou ajouter une vue d'erreur par défaut
        }

      case "ALL REQ":
      case "ADMIN CATÉGORIES":
        retour = "col-xl-10 col-lg-12 col-md-12 col-sm-12";
        console.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "ADMIN MÉTIERS":
      case "ADMIN ACTIONS":
        // BOOTSTRAP OK
        retour = "col-xl-10 col-lg-12 col-md-12 col-sm-12";
        console.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      default:
        retour = "col-xl-3 col-lg-3 col-md-2 col-sm-2";

        console.log("App AgenceContent getZone2Class DEFAULT", isMenuToggled, tiersType, retour);
        return retour;
    }
  };

  const getZone3Class = (selectedMenu, tiersType) => {
    let retour;

      if (["REQUÊTES", "ATTENTES"].includes(selectedMenu)) {
        // En fonction du tiers on ajuste l'affichage
        switch (tiersType) {
          case "AGENCE":
          case "PRESTATAIRE":
            retour = "col-xl-2 col-lg-2 col-md-2 col-sm-2";
            console.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
            return retour;

          default:
            return null; // Ou ajouter une vue d'erreur par défaut
        }
      }

      if (
        [

          "NEW CLIENT",
          "NEW MODÈLE",
          "NEW PRESTATAIRE",
          "NEW PARTENAIRE",
          "ADMIN MODÈLES",
        ].includes(selectedMenu)
      ) {
        // BOOTSTRAP OK
        retour = "col-xl-3 col-lg-5 col-md-5 col-sm-5";
        console.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        [
          "CLIENTS",
          "PARTENAIRES",
          "ALL AGENCES",
          "ADMIN AGENCES",
          "ADMIN GÉNÉRAL",
          "UTILISATEURS",
          "NEW CLIENT",
          "NEW MODÈLE",
          "NEW AGENCE",
        ].includes(selectedMenu)
      ) {
        // BOOTSTRAP OK
        retour = "col-xl-4 col-lg-6 col-md-6 col-sm-6";
        console.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (["ALL DOCUMENTS"].includes(selectedMenu)) {
        retour = "col-xl-5 col-lg-5 col-md-5 col-sm-5";
        console.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }
      if (
        [
          "PROJETS CLIENTS",
          "PROJETS PARTENAIRES",
          "ALL CLIENTS",
          "ALL PRESTATAIRES",
          "ALL PARTENAIRES",
          "ALL PROJETS",
        ].includes(selectedMenu)
      ) {
        // Changement BOOTSTRAP OK
        retour = "col-xl-6 col-lg-8 col-md-8 col-sm-8";
        console.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (["SYNTHÈSES", "ALL DASHBOARD"].includes(selectedMenu)) {
        retour = "col-xl-6 col-lg-8 col-md-8 col-sm-8";
        console.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (["ADMIN ACTIONS", "ADMIN MÉTIERS"].includes(selectedMenu)) {
        // Pas de Zone 3
        return null;
      }
      return null;
    // }
  };

  const getZone4Class = (selectedMenu, tiersType) => {
    let retour; 
 
      if (
        [
          "UTILISATEURS",
          "ADMIN AGENCES",
          "ADMIN GÉNÉRAL",
          "ALL AGENCES",
          "NEW PRESTATAIRE",
          "NEW CLIENT",
          "NEW MODÈLE",
          "NEW PARTENAIRE",
          "NEW AGENCE",
        ].includes(selectedMenu)
      ) {
        retour = "col-xl-3 col-lg-3 col-md-3 col-sm-3";
        console.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        [
          "PROJETS CLIENTS",
          "PROJETS PARTENAIRES",
          "ALL CLIENTS",
          "ALL PRESTATAIRES",
          "ALL PARTENAIRES",
          "ALL PROJETS",
          "ALL DASHBOARD",
          "SYNTHÈSES",
        ].includes(selectedMenu)
      ) {
        retour = "col-xl-2 col-lg-2 col-md-2 col-sm-2";
        console.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        ["CLIENTS", "PARTENAIRES",  "ADMIN MODÈLES"].includes(
          selectedMenu
        )
      ) {
        retour = "col-xl-4 col-lg-4 col-md-4 col-sm-4";
        console.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        [
          "ALL DASHBOARD",
          "SYNTHÈSES",
          "REQUÊTES",
          "ADMIN ACTIONS",
          "ADMIN MÉTIERS",
          "ALL DOCUMENTS",
        ].includes(selectedMenu)
      ) {

        retour = null;
        console.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }
      return null;
    // }
  };

  // console.log("App AgenceContent selectedMenu FIN", selectedMenu);
  const zone1Class = getZone1Class(tiersType);
  const zone2Class = getZone2Class(selectedMenu, tiersType);
  const zone3Class = getZone3Class(selectedMenu, tiersType);
  const zone4Class = getZone4Class(selectedMenu, tiersType);

  return (
    <div className="row">
      {isMenuToggled ? (
        <>
           <div className="col-lg-4 col-md-4 col-sm-4 order-first">
            <Zone1 />
          </div>
        </>
      ) : (
        <>
          { zone1Class && (
            <div className={zone1Class}>
              <Zone1 />
            </div>
          )}

          {zone2Class && (
            <div className={zone2Class}>
              <Zone2 />
            </div>
          )}

          {zone3Class && (
            <div className={zone3Class}>
              <Zone3 />
            </div>
          )}

          {zone4Class && (
            <div className={zone4Class}>
              <Zone4 />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default App;
